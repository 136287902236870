export const jobOffers = [
  {
    name: "Operator koparko - ładowarki",
    segments: [
      {
        title: "Twój zakres obowiązków",
        body: `
<ul>
<li>Obsługa koparko-ładowarki zgodnie z zasadami i procedurami bezpiecznej pracy</li>
<li>Wykonywanie prac związanych z załadunkiem, rozładunkiem i przemieszczaniem materiałów, ziemi, gruzu lub innych surowców</li>
<li>Wykonywanie kopania, wykopywania i napełniania wykopów, dołów czy rowów na budowie lub terenie robót drogowych</li>
<li>Sprawdzanie i konserwacja koparko-ładowarki przed rozpoczęciem pracy oraz dbanie o jej właściwe działanie i stan techniczny</li>
<li>Utrzymywanie porządku i czystości na terenie robót oraz na stanowisku pracy</li>
<li>Zapewnienie przestrzegania wytycznych bezpieczeństwa i higieny pracy</li>
<li>Praca w zespołach przy większych projektach i współpraca z innymi pracownikami budowlanymi</li>
<li>Raportowanie wszelkich problemów, awarii lub nieprawidłowości związanych z pracą koparko-ładowarki</li>
<li>Aktualizacja swojej wiedzy i umiejętności związanych z obsługą i bezpiecznym użytkowaniem koparko-ładowarki.</li>
</ul>
`,
      },
      {
        title: "Nasze wymagania",
        body: `<ul>
<li>Przestrzeganie terminów i zaleceń dotyczących wykonywania zadań</li>
<li>Zgłaszanie awarii maszyny lub nieprawidłowości w jej działaniu odpowiednim służbom</li>
<li>Prowadzenie ewidencji dotyczących użytkowania maszyny i raportowanie wykonanych prac</li>
<li>Ciągłe doskonalenie umiejętności i podnoszenie kwalifikacji z zakresu obsługi koparko-ładowarki</li>
</ul>`,
      },
      {
        title: "To oferujemy",
        body: `
<ul>
<li>zatrudnienie w stabilnej firmie o ugruntowanej pozycji rynkowej,</li>
<li>forma zatrudnienia jaką preferujesz.</li>
</ul>

`,
      },
    ],
  },
  {
    name: "Specjalista ds. pozyskiwania gruntów (OZE)",
    segments: [
      {
        title: "Twój zakres obowiązków",
        body: `

<ul>
<li>pozyskiwanie nieruchomości gruntowych pod budowę elektrowni fotowoltaicznych, wiatrowych, magazynów energii oraz infrastruktury towarzyszącej,</li>
<li>weryfikacja lokalizacji w terenie oraz analizy mapowe,</li>
<li>spotkania i negocjacje z właścicielami gruntów,</li>
<li>negocjacje i zawieranie umów zabezpieczających prawa do terenu,</li>
<li>utrzymywanie relacji z właścicielami gruntów,</li>
<li>współpraca z innymi działami w celu realizacji zadania,</li>
<li>współpraca z jednostkami administracji samorządowej i rządowej w zakresie pozyskania dokumentów niezbędnych do realizacji inwestycji,</li>
<li>wsparcie w uzyskiwaniu decyzji administracyjnych.</li>
</ul>`,
      },
      {
        title: "Nasze wymagania",
        body: `
<ul>
<li>doświadczenie w pozyskiwaniu gruntów,</li>
<li>umiejętności prowadzenia efektywnych negocjacji handlowych,</li>
<li>chęć do pracy w terenie,</li>
<li>umiejętność bardzo dobrej organizacji pracy własnej,</li>
<li>łatwość nawiązywania kontaktów, umiejętność budowania relacji,</li>
<li>prawo jazdy kat. B – wymóg konieczny.</li>
</ul>`,
      },
      {
        title: "Mile widziane",
        body: `
<ul>
<li>obsługa programów pakietu MS Office - Excel, Word,</li>
<li>doświadczenie jako przedstawiciel handlowy, specjalista ds. pozyskiwania nieruchomości lub doświadczenie w branży rolniczej.</li>
</ul>`,
      },
      {
        title: "To oferujemy",
        body: `

<ul>
<li>atrakcyjne wynagrodzenie wraz z klarownym systemem prowizyjnym,</li>
<li>samochód, telefon i komputer służbowy oraz nowoczesne narzędzia pracy,</li>
<li>pracę w stabilnej i dynamicznie rozwijającej się firmie,</li>
<li>możliwość rozwoju zawodowego,</li>
<li>praca w niekorporacyjnym środowisku.</li>
</ul>`,
      },
    ],
  },
  //
  /* {
    name: "",
    segments: [
      {
        title: "Twój zakres obowiązków",
        body: ``,
      },
      {
        title: "Nasze wymagania",
        body: ``
      },
      {
        title: "To oferujemy",
        body: ``
      }
    ],
  },*/
  /*{
    name: "",
    segments: [
      {
        title: "Twój zakres obowiązków",
        body: ``,
      },
      {
        title: "Nasze wymagania",
        body: ``
      },
      {
        title: "To oferujemy",
        body: ``
      }
    ],
  },*/
]
export const jobOffersCount = jobOffers.length
