import styled from "styled-components"
// import posed from "react-pose"
// import { DropdownPosed } from "../../navbar/navbarStyle"

export const MobileButtonStyled = styled.button`
  height: 66px;
  background-color: ${props => props.bg};
  border: none;
  color: ${props => props.color};
  border: ${props => `2px solid ${props.border}`};
  font-size: 20px;
  font-weight: 500;
  border-radius: 4px;
  position: relative;
  width: 100%;
  transition: 0.2s background-color;

  ::after {
    position: absolute;
    content: "";
    top: 50%;
    right: 20px;
    transform: translateY(-50%) rotate(-45deg);
    width: ${props => (props.smallArrow === true ? "6px" : "14px")};
    display: ${props => (props.arrow ? "block" : "none")};
    height: ${props => (props.smallArrow === true ? "6px" : "14px")};
    border-bottom: ${props => (props.smallArrow === true ? "1px" : "2px")} solid
      ${props =>
        props.bg === "#5D5D5D" || props.bg === "#94C835" ? "#ffff" : "#5D5D5D"};
    border-right: ${props => (props.smallArrow === true ? "1px" : "2px")} solid
      ${props =>
        props.bg === "#5D5D5D" || props.bg === "#94C835" ? "#ffff" : "#5D5D5D"};
  }

  &[disabled] {
    opacity: 0.5;
    cursor: no-drop;
  }

  &.hover:hover {
    background-color: #8abc30;
    color: #fff;
  }
`
export const NavbarButtonStyled = styled.button`
  color: ${props => (props.theme === "dark" ? "#000" : "#fff")};
  background: ${props => (props.theme === "hover" ? "#94c835" : "transparent")};
  border: 2px solid #94c835;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  padding: 0 20px;
  text-transform: uppercase;
  border-radius: 4px;
  white-space: nowrap;
  height: 42px;
  transition: 300ms;
  &:hover {
    background-color: #94c835;
    color: #fff;
  }
`
// export const LanguageDropdown = styled(posed.div(DropdownPosed))`
//   z-index: 9999999;
//   background-color: #fff;
//   padding: 35px;
//   border-radius: 4px;
//   box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
// `;

export const PhoneButtonStyled = styled.button`
  height: 66px;
  background-color: ${props => (props.bg ? props.bg : "#94c835")};
  border: none;
  color: ${props => (props.color ? props.color : "#fff")};
  font-size: 20px;
  font-weight: 500;
  border-radius: 4px;
  width: 259px;
  display: flex;
  justify-content: center;
  padding: 0;
  // width: ${props => (props.noIcon ? "100%" : "259px")};
  max-width: 271px;
  transition: 300ms;
  white-space: nowrap;

  //&:hover .icon-phone svg{
  //animation: zoomIconPhone 600ms;
  //animation-iteration-count: 2;
  //}
  // &:hover .icon-email svg {
  //  animation: zoomIconEnvelope 600ms;
  //  animation-iteration-count: 2;
  // }
  span {
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    //padding: 0 22px;
    width: 204px;
  }
  .icon-phone {
    width: 55px;
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid rgba(255, 255, 255, 0.35);
    transition: 300ms;
    svg {
      height: 35px;
      width: 35px;
      opacity: 0.8;
      fill: white !important;
      //transform: scale(0.9);
        transition: 300ms;
    }
  }
  .icon-email {
    width: 55px;
    height: 66px;
    overflow: hidden;
    border-right: 1px solid rgba(255, 255, 255, 0.25);
    transition: 300ms;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      height: 38px;
      transform: scale(0.6);
      width: 44px;
      opacity: 0.8;
      fill: white !important;
      transition: 300ms;
    }
  }
  @keyframes zoomIconPhone {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

  @keyframes zoomIconEnvelope {
  0% {
    transform: scale(0.6);
  }

  50% {
    transform: scale(0.48);
  }
  100% {
    transform: scale(0.6);
  }
}
`
